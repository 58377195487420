import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
    RoundedButton,
    FeatureLayout,
    DrawDownHeader,
    PayLaterHeader,
    FundNowHeader,
    VideoViewer
} from '@lendica/components'
import PostAddIcon from '@mui/icons-material/PostAdd';
import LaunchIcon from '@mui/icons-material/Launch';

const steps = [
    {
        logo: <PayLaterHeader />,
        label: 'PayLater',
        next: 'DrawDown',
        header: 'Pay suppliers when you want',
        body: 'PayLater is an A/P tool that allows you to pay your invoices over two to ten weeks instead of upfront. Use PayLater to delay payments to your vendors while you collect cash from customers.',
        img: 'https://static.golendica.com/assets/svg/betalist_paylater_graphics.svg',
        learnMoreLink: 'https://docsend.com/view/cvbsxpdqhzg94pig',
        videoUrl: 'https://player.vimeo.com/video/733671440?h=3dfd3d252f&badge=0&autopause=0&player_id=0&app_id=58479/embed',
        background: 'https://static.golendica.com/assets/svg/blob_2.svg'
    },
    {
        logo: <DrawDownHeader />,
        label: 'DrawDown',
        next: 'FundNow',
        header: 'Scale your business with non-dilutive capital',
        body: 'DrawDown is a working capital management tool that allows you to borrow against your future cash flow. Use DrawDown to buy equipment, invest in marketing, and scale your operations without giving up equity or control.',
        img: 'https://static.golendica.com/assets/svg/betalist_drawdown_graphics.svg',
        learnMoreLink: 'https://docsend.com/view/3n552jrzpbcssi2c',
        videoUrl: 'https://player.vimeo.com/video/769721782?h=5b37aa5d2a&badge=0&autopause=0&player_id=0&app_id=58479/embed',
        background: 'https://static.golendica.com/assets/svg/blob_3.svg'
    },
    {
        logo: <FundNowHeader />,
        label: 'FundNow',
        next: 'PayLater',
        header: 'Collect customer payments when you need',
        body: 'FundNow is an A/R tool that allows you to get funded instantly for open invoices. Use FundNow to get paid early by customers that have been offered net terms.',
        img: 'https://static.golendica.com/assets/svg/betalist_fundnow_graphics.svg',
        learnMoreLink: 'https://docsend.com/view/tr6ccrq9sz8i3age',
        videoUrl: 'https://player.vimeo.com/video/738233728?h=08a3c8de94&badge=0&autopause=0&player_id=0&app_id=58479/embed',
        background: 'https://static.golendica.com/assets/svg/blob_1.svg'
    },
];

export const Waitlist = ({ product, callback }) => {
    const step = steps.find(x => x.label === product)

    return (
        <>
            <FeatureLayout>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        boxSizing: 'border-box',
                        px: 2,
                        pb: 4,
                        pt: 6,
                        backgroundColor: '#F6F6F7',
                        background: `no-repeat center/135% url(${step.background}) #F2f2f8`,
                    }}
                >
                    <Box
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
                    >
                        {step.logo}
                    </Box>

                    <Box
                        key={step.label}
                        sx={{
                            textAlign: 'center',
                            px: 2,
                            boxSizing: 'border-box',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                            justifyContent: 'space-between',
                            overflow: 'hidden'
                        }}
                    >
                        <Typography variant='h1' fontSize={30} fontWeight={800} sx={{ mt: 4 }}>{step.header}</Typography>
                        <Typography variant='body2' fontSize={14} color="text.secondary" sx={{ mt: 2, mb: 4 }}>{step.body}</Typography>
                        <Box
                            component={'img'}
                            sx={{ width: '100%', height: { xs: 300, sm: 300, md: 300, lg: 300, xl: 450 }, mb: 4 }}
                            src={step.img}
                            alt=""
                        />
                    </Box>

                    <Box sx={{ height: 'fit-content', bgcolor: 'transparent' }}>
                        <RoundedButton
                            size="medium"
                            variant="contained"
                            color="secondary"
                            sx={{ mt: 1, width: '100%', boxSizing: 'border-box' }}
                            endIcon={<PostAddIcon />}
                            onClick={callback}
                        >
                            {`Activate ${step.label}`}
                        </RoundedButton>
                        <RoundedButton
                            size="medium"
                            variant="text"
                            target="_blank"
                            href={step.learnMoreLink}
                            sx={{ mt: 1, width: '100%', boxSizing: 'border-box' }}
                            endIcon={<LaunchIcon />}
                        >
                            Learn more
                        </RoundedButton>
                    </Box>
                </Box>
            </FeatureLayout>
            <VideoViewer videoUrl={step.videoUrl} />
        </>
    );
}