import { Box, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const CircularIndeterminate = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#9292a4',
            }}
        >
            <CircularProgress color="inherit" />
        </Box>
    );
};

export const SidePanelLayout = ({ children, loading, redirectRoute }) => {
    const navigate = useNavigate()

    return (
        <Box sx={{ py: 2, px: 4, flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minWidth: 300 }}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Button onClick={() => navigate(redirectRoute)}>Close</Button>
            </Box>
            {loading ? <CircularIndeterminate /> : children}
        </Box>
    );
};
