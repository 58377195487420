import { parse, format, parseISO } from 'date-fns';

export const financial = value => {
    return Number.parseFloat(value).toFixed(2);
};

export const currencyFormatter = (value, omitCents = false) => {
    try {
        const result = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value);
        if (omitCents) {
            return result.split('.')[0];
        }
        return result;
    } catch (e) {
        return value;
    }
};

export const percentageFormatter = (value, precision = 2) => {
    try {
        return Number(value * 100).toFixed(precision) + '%';
    } catch (e) {
        return value;
    }
};

export const stringFormatter = value => {
    try {
        return capitalize(value.split('_').join(' '));
    } catch (e) {
        return value;
    }
};

export const capitalize = value => {
    if (typeof value !== 'string') {
        return value;
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * Format ISO date or date-time string to locale month string with numeric year and day
 * @param {String} dateString must be ISO 8601 format (YYYY-MM-DD or YYYY-MM-DDTHH:mm:ss.sssZ)
 * @returns {String}
 */
export const dateFormatter = (dateString, long = true) => {
    if (typeof dateString !== 'string') {
        return dateString;
    }
    if (dateString.length === 10) {
        dateString = dateString.replace(/-/g, '/');
    }
    let date = new Date(dateString);
    if (!isValidDate(date)) {
        return dateString;
    }
    return long
        ? date.toLocaleString('default', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })
        : date.toLocaleDateString();
};

export const formatISO = (date) => {
    let formattedDate = parseISO(date)
    formattedDate = format(formattedDate, 'yyyy-MM-dd')
    return formattedDate
}

export const formatDateLong = date => {
    let formattedDate = parse(date, 'yyyy-MM-dd', new Date());
    formattedDate = format(formattedDate, 'MMMM d, yyyy');
    return formattedDate;
};

export const formatDateShort = date => {
    let formattedDate = parse(date, 'yyyy-MM-dd', new Date());
    formattedDate = format(formattedDate, 'MMM d, yyyy');
    return formattedDate;
};

export const formatDateDashed = (date, year = true) => {
    let formattedDate = parse(date, 'yyyy-MM-dd', new Date());

    if (year) {
        formattedDate = format(formattedDate, 'MM-d-yy');
    } else {
        formattedDate = format(formattedDate, 'MM-d');
    }
    return formattedDate;
};

export const formatDateSlashed = (date, year = true) => {
    let formattedDate = parse(date, 'yyyy-MM-dd', new Date());

    if (year) {
        formattedDate = format(formattedDate, 'MM/d/yy');
    } else {
        formattedDate = format(formattedDate, 'MM/d');
    }
    return formattedDate;
};

export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

export const formatProductName = product => {
    if (product === 'paylater') {
        return 'PayLater';
    } else if (product === 'drawdown') {
        return 'DrawDown';
    } else if (product === 'fundnow') {
        return 'FundNow';
    } else {
        return '';
    }
};
