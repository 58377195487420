import { FC } from 'react';
import { Box } from '@mui/material';
import { Logo } from './Logo';
import { keyframes } from '@emotion/react';

const pulse = keyframes`
    0% {
        opacity: 0;
        transform: scale(0.2);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
`;

const grow = keyframes`
    0% {
        opacity: 0;
        transform: scale(0.2);
        border-radius: 50%;
        width: 1px;
        height: 1px;
        background: #ED7A56;
    }
    50% {
        opacity: 1;
        transform: scale(1);
        border-radius: 50%;
        width: 120px;
        height: 120px;
        background: #ED7A56;
    }
    75% {
        opacity: 0.5;
        transform: scale(10);
        border-radius: 50%;
        width: 120px;
        height: 120px;
        background: radial-gradient(#FCFCFD66 0%, #FFFFFF00 85%, #FFFFFF00 100%);
    }
    100% {
        opacity: 0;
        transform: scale(18);
        border-radius: 50%;
        width: 120px;
        height: 120px;
        background: radial-gradient(#FCFCFD66 0%, #FCFCFD00 85%, #FCFCFD00 100%);
    }
`;

export const SplashScreen: FC = () => {
    return (
        <Box
            justifyContent="center"
            alignItems="center"
            height="100%"
            bgcolor="#0E0E2C"
            display="grid"
            width="100%"
        >
            <Box
                sx={{
                    gridColumn: '1',
                    gridRow: '1',
                    animation: `${grow} 2.5s ease-in-out 1`,
                    alignSelf: 'center',
                }}
            />
            <Box
                sx={{
                    gridColumn: '1',
                    gridRow: '1',
                    width: '120px',
                    height: '120px',
                    animation: `${pulse} 1.5s ease-in-out 1`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Logo dark={false} />
            </Box>
        </Box>
    );
};
