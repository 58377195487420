import { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { PhoneAuth, ProtectedRoute, AccountProvider, useAccountContext } from '@lendica/auth';
import { Home } from './Home';
import { baseURL } from './api';

const PhoneAuthContainer = () => {
    const navigate = useNavigate();
    const { storePortedTokens } = useAccountContext();
    return (
        <PhoneAuth
            baseURL={baseURL}
            onSuccess={async tokens => {
                const result = await storePortedTokens(tokens);
                // console.log('storePortedTokens', result);
                navigate('/home');
            }}
        />
    );
};

export const App = () => {
    const [search] = useState(window.location.search);
    // console.log(search);
    const navigate = useNavigate();
    // endLocation="https://paylater.app-idev.golendica.com/paylater" "https://checkout.app-idev.golendica.com/" "http://localhost:5001"

    return (
        <AccountProvider
            onAuthSuccess={() => navigate('/home')}
            onAccountClear={() => navigate('/')}
        >
            <Routes>
                <Route element={<ProtectedRoute />}>
                    <Route
                        path="home"
                        element={
                            <Home
                                search={search}
                                endLocation="https://checkout.app-idev.golendica.com/"
                            />
                        }
                    />
                </Route>
                <Route path="*" element={<PhoneAuthContainer />} />
            </Routes>
        </AccountProvider>
    );
};
