import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';
import { LoadingScreen } from './LoadingScreen';

export const FeatureLayout: FC<{ loading?: boolean, children: any }> = ({ children, loading }) => {
    return (
        <Box
            sx={{
                overflow: 'auto',
                width: 480,
                flexShrink: 0,
                bgcolor: 'background.paper',
                height: '100%'
            }}
        >
            {loading ?
                <LoadingScreen />
                : children}
        </Box>
    );
};