import { render } from 'react-dom';
import { theme } from './theme';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { App } from './App';

render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<App />} />
            </Routes>
        </BrowserRouter>
    </ThemeProvider>,
    document.getElementById('app')
);