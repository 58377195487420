import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { RoundedButton } from '@lendica/components';
import { openedWindow, oauthSalesforce, findSalesforceSaas } from './utils/common';
import { fetchActiveConnections, fetchCompanySummary } from './api';
import { useAccountContext } from '@lendica/auth';

let interval = null;

export const Home = ({ search, endLocation }) => {
    const [connected, setConnected] = useState(false);
    const { clearAccounts } = useAccountContext();

    const redirectToEndLocation = () => {
        if (interval) {
            clearInterval(interval);
        }

        window.open(`${endLocation}${search}`, '_self');
        setConnected(true);
    };

    const salesforceSignIn = async () => {
        const companySummary = await fetchCompanySummary(clearAccounts);
        const connections = await fetchActiveConnections(clearAccounts);

        if (findSalesforceSaas(connections)) {
            redirectToEndLocation();
            return;
        }

        //TODO: pass env variable
        oauthSalesforce(companySummary.company.id, 'idev');

        interval = setInterval(async () => {
            if (!openedWindow.closed) {
                return;
            }

            const connections = await fetchActiveConnections();

            if (findSalesforceSaas(connections)) {
                redirectToEndLocation();
            } else {
                clearInterval(interval);
            }
        }, 1000);
    };

    return (
        <Box
            sx={{
                justifyContent: 'center',
                display: 'flex',
                margin: '0 auto',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Typography variant="body1">
                Lendica connects with Salesforce to provide instant capital to your business.
            </Typography>
            <RoundedButton
                size="medium"
                color="secondary"
                variant="contained"
                disabled={connected}
                fullWidth
                sx={{ marginTop: 2, width: 120 }}
                onClick={salesforceSignIn}
            >
                {connected ? 'connected' : 'sign in'}
            </RoundedButton>
        </Box>
    );
};
