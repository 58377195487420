import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';

export const VideoViewer: FC<{ videoUrl: string }> = ({ videoUrl }) => {
    return videoUrl ? (
        <Box sx={{ p: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', minWidth: 720 }}>
            <embed src={videoUrl} style={{ flexGrow: 1 }} />
        </Box>
    ) : (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <CircularProgress />
        </Box>
    );
};
