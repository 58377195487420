export let openedWindow = null;

export const findSalesforceSaas = (items = []) => {
    return items.find(item => item.saas_name === 'salesforce' && item.active);
};

export const oauthSalesforce = (companyId, env = '') => {
    const parameters = 'location=1,width=800,height=650';
    const service = 'salesforce';

    if (env === 'idev') {
        openedWindow = window.open(
            `https://micro-oauth-dev.herokuapp.com/oauth/${service}?company_id=${companyId}`,
            'connectPopup',
            parameters
        );
    } else if (env === 'prod') {
        openedWindow = window.open(
            `https://oauth.app.golendica.com/oauth/${service}?company_id=${companyId}`,
            'connectPopup',
            parameters
        );
    } else {
        openedWindow = window.open(
            `http://localhost:5200/oauth/salesforce?company_id=${companyId}`,
            'connectPopup',
            parameters
        );
    }
};
